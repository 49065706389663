import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">NUOVA CULTURA D’IMPRESA.it</h1>
        <img src='./logo_nuovacultura.jpg' className="App-logo" alt="logo" />
        <p className="App-para">
        Nato dalla pluriennale esperienza professionale di Luigi Tattolo nella consulenza aziendale, questo percorso rivoluzionerà l’approccio al lavoro e alla vita, la motivazione e la creatività delle risorse umane all’interno della tua impresa.
        </p>
        <div className="App-video-container">
          <p className="App-video-title">
          Vita Strategica su Buongiorno Regione Puglia
          </p>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/l6ZQ-BDjVco" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </header>
      <footer class="App-footer">
        <p className="App-copyright">&copy; 2023 Nuova Cultura d'Impresa di Luigi Tattolo. All Rights Reserved.</p>
      </footer>
      <div className="App-links">
          <h6>Download Brochure</h6>
          <a
            className="App-link"
            href='./NCI_COMPANY_PROFILE.pdf'
            target="_blank"
            rel="noopener noreferrer"
          >
            Company Profile
          </a>
          <a
            className="App-link"
            href='./BROCHURE_VITA_STRATEGICA.pdf'
            target="_blank"
            rel="noopener noreferrer"
          >
            Vita Strategica
          </a>
        </div>

    </div>
    
  );
}

export default App;
